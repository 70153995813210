import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import 'bootstrap/dist/css/bootstrap.min.css'
import { productsArray } from "./productStore";
import "./tickets.css";

export default function Tickets() {
  const [selectedSize, setSelectedSize] = useState('all')

  const handleSizeChange = event => {
    setSelectedSize(event.target.value)
  }

  const filteredProducts = productsArray.filter(
    product => selectedSize === 'all' || product.size === selectedSize || product.title === 'Ticket'
  );

  const handleClick = () => {
    window.location.href = "https://indianabigfootconference.simpletix.com/";
  };

  // SIMPLE TIX --- UNCOMMENT ONCE TICKETS GO ON SALE!!!!!!!!

    // useEffect(() => {
    //   const script = document.createElement("script")
    //   script.src = "https://embed.prod.simpletix.com/assets/widget/embediFrame.js"
    //   script.setAttribute(
    //     "data-url",
    //     "https://embed.prod.simpletix.com/961e047d-8a31-4e13-8d15-9d8996e1b8f9/195905?smtxorigin=12"
    //   )
    //   script.async = true
    //   document.getElementById("simpletixEmbed").appendChild(script)
    // }, [])

  return (
    <section id="tickets">
      <div className="ticket-container">
        <div className="ticket-content">
          <span className="ticket-topper">Tickets & Merch</span>
          <h2 className="ticket-title">Purchase Conference Tickets & Merch</h2>
          <h3 className="weekend-pass">
          Tickets on sale now!
          </h3>
          <p>Fireside Chat tickets are now sold out</p>
          <div className="vendor-link-btn">
        <button onClick={handleClick}>
          Buy Tickets Here
        </button>
      </div>

          
          {/* SIMPLE TIX --- UNCOMMENT ONCE TICKETS GO ON SALE!!!!!!!!!!
          <div>
            <div style={{ width: "100%", textAlign: "left" }}>
              <div id="simpletixEmbed"></div>
              <div
              style={{
                fontFamily: "Helvetica, Ariel",
                fontSize: "12px",
                padding: "10px 0 5px",
                margin: "2px",
                width: "100%",
                textAlign: "left"
              }}
              >
                <a
                style={{ color: "#ADB0B6", textDecoration: "none"}}
                target="_parent"
                href="https://www.simpletix.com/?utm_source=embed&utm_medium=web&utm_campaign=embedwidget&utm_content=footer&a=logo"
                >
                  Powered by SimpleTix
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
