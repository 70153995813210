import React from "react";
import { FaWarehouse } from "react-icons/fa";

import './sponsors.css'

const Sponsors = () => {
     return (
          <section id="sponsor">
      <div className="sponsors-container">
        <div className="sponsors-content">
          <span className="sponsors-topper">Sponsor Information</span>
          <h2 className="sponsors-title">Sponsor Donation</h2>

          <p className="sponsor-p">If you are interested in being a sponsor or have questions, please make your donation online.  If you have questions, please send an email to bfc2024info@gmail.com and we will get back to you. Thank you for your desire to sponsor our event. (Please note that any of these sponsorships can be anonymous if you wish. Just indicate that when you make your donation). Once you make a donation, the IBRO team will follow up with an email for more information on your donation.</p>

          <div className="sponsor-master-container">
          <div className="container">
          <div className="sponsor-card-container">
            <div className="sponsor-card">
              <h4 className="sponsor-name">$500 Tier</h4>
              <p>• Name announced during conference</p>
               <p>• Name on event T-shirt</p>
               <p>• Name on event banner</p>
               <p>• Name on conference website</p>
              <a href="https://square.link/u/V0uUNHxQ" class="sponsor-button">Submit Payment</a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="sponsor-card-container">
            <div className="sponsor-card">
              <h4 className="sponsor-name">$250 Tier</h4>
               <p>• Name on event T-shirt</p>
               <p>• Name on event banner</p>
               <p>• Name on conference website</p>
              <a href="https://square.link/u/k67IT4X5" class="sponsor-button">Submit Payment</a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="sponsor-card-container">
            <div className="sponsor-card">
              <h4 className="sponsor-name">(Sponsor a Speaker) $250 Tier</h4>
               <p>Name announced during intro of speaker</p>
               <p>• Name on speaker poster</p>
               <p>• Name on speaker page on website</p>
              <a href="https://square.link/u/xRRuzpBT" class="sponsor-button">Submit Payment</a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="sponsor-card-container">
            <div className="sponsor-card">
              <h4 className="sponsor-name">$100 Tier</h4>
              <p>• Name on event banner</p>
              <a href="https://square.link/u/V55m70LG" class="sponsor-button">Submit Payment</a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="sponsor-card-container">
            <div className="sponsor-card">
              <h4 className="sponsor-name">$50 Tier</h4>
              <a href="https://square.link/u/EBWkBYJw" class="sponsor-button">Submit Payment</a>
            </div>
          </div>
        </div>
          </div>

        </div>
      </div>
    </section>
     )
}

export default Sponsors;