import React from "react";

import "./speakers.css";

import gregYost from "../components/img/greg.jpg";
import ronny from "../components/img/Ronny LeBlanc.jpg";
import tom from "../components/img/Tom Shay.png";
import dusty from "../components/img/Dusty Ruth.png";
import mike from "../components/img/Mike Miller.png";

export default function Speakers() {

  const speakerSchedule = [
    { time: "9am - 9:30am", speaker: "LeRoy Nail - IBRO Host of Event" },
    { time: "9:30am - 10:30am", speaker: "Michael Miller" },
    { time: "10:30am - 11am", speaker: "Break" },
    { time: "11am - 12pm", speaker: "Tom Shay" },
    { time: "12pm - 2pm", speaker: "Lunch Break" },
    { time: "2pm - 3pm", speaker: "Ronnie LeBlanc" },
    { time: "3pm - 3:30pm", speaker: "Break" },
    { time: "3:30pm - 4:30pm", speaker: "Dusty Ruth" },
    { time: "4:30pm - 5pm", speaker: "Break" },
    { time: "5pm - 6pm", speaker: "Greg Yost AKA Squatchman" },
  ]

  const speakers = [
    {
      name: "Ronny LeBlanc",
      description:
        "Globally recognized figure in the world of paranormal, bigfoot, &  UFOs",
      picture: ronny,
    },
    {
      name: "Tom Shay",
      description:
        "Founder of Northern Bigfoot Research Group",
      picture: tom,
    },
    {
      name: "Dusty Ruth",
      description:
        "Ohio Field Researcher",
      picture: dusty,
    },
    {
      name: "Greg Yost",
      description: "Squatchman - Indiana Field Researcher",
      picture: gregYost,
    },
    {
      name: "Mike Miller",
      description:
        "Ohio Night Stalkers",
      picture: mike,
    },
  ];

  return (
    <section id="speaker">
      <div className="speakers-container">
        <div className="speakers-content">
          <span className="speakers-topper">Speakers</span>
          <h2 className="speakers-title">Meet the Speakers</h2>
        </div>
        <div className="speakers-grid">
          {speakers.map((speaker, index) => (
            <div
              key={index}
              className="speaker-card"
            >
              <img src={speaker.picture} alt={speaker.name} className="speaker-img" />
              <h3 className="speaker-name">{speaker.name}</h3>
              <p className="speaker-description">{speaker.description}</p>
            </div>
          ))}
        </div>
      </div>
  
      {/* Speaker Schedule Card */}
      <div className="schedule-wrapper">
        <div className="schedule-card">
          <h2 className="speaker-schedule-title">Speaker Schedule</h2>
          <div className="schedules">
            {speakerSchedule.map((item, index) => (
              <div key={index} className="schedule">
                <span className="time">{item.time}</span>
                <span className="schedule-name">{item.speaker}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}