import React from "react";
import "./fireside.css";

export default function Fireside() {

    return (
        <div className="fireside">
            <div className="fireside-container">
                <div className="fireside-content">
                    <span className="fireside-topper">What is the Fireside Chat?</span>
                    <h2 className="fireside-title">Friday, September 26th, 2025</h2>
                    <h2 className="fireside-title">8pm to 10:45pm</h2>
                    <p className="sold-out">Fireside Chat tickets are now sold out</p>
                    <p className="fireside-text">After the town hall, we will travel to an undisclosed location (location will be shared once gathered in the parking lot). We will sit around the fire and listen for sounds of Bigfoot and talk about experiences; this is within a hot spot that the IBRO frequently has activity or receives reports of activity. Hot dogs, potato salad, and tea will be provided. To participate, please purchase the "2025 Conference + Fireside Chat" ticket. At registration, you will be given a different color wristband to signify your participation. Spots are on a first-come, first-served basis and are limited to only 30 participants, so don’t delay—purchase your ticket today!</p>
                </div>
            </div>
        </div>
    )
}