import React from "react";
import { Link, NavLink } from "react-router-dom";

import "./home.css";
import ibroFlyer from "../components/img/ibro-flyer.png"

import HeaderImg from "./img/browncounty.jpg";

const Home = () => {

  const fridaySchedule = [
    { time: "3pm - 7pm", event: "Doors open for vendor sales" },
    { time: "4pm - 7pm", event: "Pre-registration for Saturday's conference" },
    { time: "3pm - 5pm", event: "Raffle tickets available" },
    { time: "5pm - 7pm", event: "Townhall" },
    { time: "8pm", event: "Venue closed" },
    { time: "8pm - 10:45pm", event: "Fireside Chat (at undisclosed location) – Requires separate ticket" }
  ];

  const saturdaySchedule = [
    { time: "8am - 7pm", event: "Doors open for vendor sales" },
    { time: "8am - 5pm", event: "Registration for speaker event" },
    { time: "8am - 5pm", event: "Raffle tickets available" },
    { time: "10:30am - 6pm", event: "Door prizes drawn between speakers (must be present to win)" },
    { time: "9am - 6pm", event: "Speakers (visit speaker page for more info)" },
    { time: "6pm - 7pm", event: "Raffle winners/auctions" },
    { time: "8pm", event: "Venue closed" }
  ];

  return (
    <div>
      {/* HEADER IMG */}
      <div className="headerImg">
        <img src={HeaderImg} alt="" />
      </div>
      
      <div id="hp">
        <div className="hp-container">
          <div className="hp-content">
            <span className="hp-topper">Indiana's 2nd Annual Bigfoot Conference</span>
            <h2 className="hp-title">Indiana Bigfoot Conference</h2>
          </div>

          <div className="hp-text-container">
            <h2>September 26th - September 27th, 2025</h2>
            <h2>The Seasons Lodge, Nashville, Indiana</h2>
            <NavLink className="tickets-btn" to="/tickets">Buy Tickets!</NavLink>
            <h3>Join us for the 2nd Annual Bigfoot Conference, taking place September 26th–27th! Immerse yourself in firsthand experiences, shop from local artists, and connect with fellow enthusiasts.</h3>
          </div>

            <h1 className="schedule-title">Schedule</h1>
            <div className="schedule-container">
              <div className="day-schedule">
                <div className="day-title">
                  <h2>Friday September 26th</h2>
                  <p>(Vendors & Town Hall open to the public)</p>
                </div>
                <div className="events">
                  {fridaySchedule.map((item, index) => (
                    <div key={index} className="event">
                      <span className="time">{item.time}</span>
                      <span className="event-name">{item.event}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="day-schedule">
        <div className="day-title">
          <h2>Saturday September 27th</h2>
          <p>(Vendors open to the public)</p>
        </div>
        <div className="events">
          {saturdaySchedule.map((item, index) => (
            <div key={index} className="event">
              <span className="time">{item.time}</span>
              <span className="event-name">{item.event}</span>
            </div>
          ))}
        </div>
      </div>
    </div>


          <div className="flyer-container">
            {/* <picture className="con-flyer">
              <img 
              loading="lazy"
              decoding="async"
              srcSet={conFlyer}
              alt="Indy Bigfoot Con Flyer"
              aria-hidden="true"
              />
            </picture> */}

            <div className="host-flyer">
              <h2>Special Thanks to IBRO for Hosting Indy Bigfoot Con</h2>
              <h2 id="site-link" ><a href="https://ibroindiana.com/">https://ibroindiana.com/</a></h2>
              <picture className="ibro-flyer">
              <img 
              loading="lazy"
              decoding="async"
              srcSet={ibroFlyer}
              alt="IBRO Flyer"
              aria-hidden="true"
              />
            </picture>
            </div>

            <div className="vendor-list">
              <h2>Vendor List</h2>
              <p>Coming soon</p>
            </div>
          </div>

          <div className="dev-team-text-container">
            <h3>Website created by Alyssa Wiser</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
